export const IconStatus = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.04102 12.4751C2.93268 15.3418 5.33269 17.5501 8.31602 18.1584"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.70898 9.15008C2.13398 4.94175 5.68398 1.66675 10.0006 1.66675C14.3173 1.66675 17.8673 4.95008 18.2923 9.15008"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.6758 18.1666C14.6508 17.5583 17.0424 15.3749 17.9508 12.5166"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
