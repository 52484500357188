export const IconWarning = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992ZM7.99992 7.33325C8.36811 7.33325 8.66659 7.63173 8.66659 7.99992V11.3338C8.66659 11.702 8.36811 12.0005 7.99992 12.0005C7.63173 12.0005 7.33325 11.702 7.33325 11.3338V7.99992C7.33325 7.63173 7.63173 7.33325 7.99992 7.33325ZM7.99992 5.99992C8.36811 5.99992 8.66659 5.70144 8.66659 5.33325C8.66659 4.96506 8.36811 4.66659 7.99992 4.66659C7.63173 4.66659 7.33325 4.96506 7.33325 5.33325C7.33325 5.70144 7.63173 5.99992 7.99992 5.99992Z"
                fill="#9A9FA5"
            />
        </svg>
    )
}
