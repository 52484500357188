export const IconStatusUp = (props) => {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.73242 15.7499C5.39076 15.7499 5.10742 15.4666 5.10742 15.1249V13.3999C5.10742 13.0582 5.39076 12.7749 5.73242 12.7749C6.07409 12.7749 6.35742 13.0582 6.35742 13.3999V15.1249C6.35742 15.4749 6.07409 15.7499 5.73242 15.7499Z"
                fill="#292D32"
            />
            <path
                d="M10 15.7501C9.65833 15.7501 9.375 15.4667 9.375 15.1251V11.6667C9.375 11.3251 9.65833 11.0417 10 11.0417C10.3417 11.0417 10.625 11.3251 10.625 11.6667V15.1251C10.625 15.4751 10.3417 15.7501 10 15.7501Z"
                fill="#292D32"
            />
            <path
                d="M14.2676 15.75C13.9259 15.75 13.6426 15.4667 13.6426 15.125V9.94165C13.6426 9.59998 13.9259 9.31665 14.2676 9.31665C14.6092 9.31665 14.8926 9.59998 14.8926 9.94165V15.125C14.8926 15.475 14.6176 15.75 14.2676 15.75Z"
                fill="#292D32"
            />
            <path
                d="M5.73266 10.9834C5.44933 10.9834 5.19933 10.7918 5.12433 10.5084C5.041 10.1751 5.24099 9.83343 5.58266 9.75009C8.64933 8.98343 11.3493 7.30843 13.4077 4.91676L13.791 4.46676C14.016 4.20843 14.4077 4.1751 14.6743 4.4001C14.9327 4.6251 14.966 5.01676 14.741 5.28343L14.3577 5.73343C12.1327 8.33343 9.19933 10.1418 5.88266 10.9668C5.83266 10.9834 5.78266 10.9834 5.73266 10.9834Z"
                fill="#292D32"
            />
            <path
                d="M14.2659 7.93333C13.9242 7.93333 13.6409 7.65 13.6409 7.30833V5.5H11.8242C11.4826 5.5 11.1992 5.21667 11.1992 4.875C11.1992 4.53333 11.4826 4.25 11.8242 4.25H14.2659C14.6076 4.25 14.8909 4.53333 14.8909 4.875V7.31667C14.8909 7.65833 14.6159 7.93333 14.2659 7.93333Z"
                fill="#292D32"
            />
            <path
                d="M12.4993 18.9584H7.49935C2.97435 18.9584 1.04102 17.0251 1.04102 12.5001V7.50008C1.04102 2.97508 2.97435 1.04175 7.49935 1.04175H12.4993C17.0243 1.04175 18.9577 2.97508 18.9577 7.50008V12.5001C18.9577 17.0251 17.0243 18.9584 12.4993 18.9584ZM7.49935 2.29175C3.65768 2.29175 2.29102 3.65841 2.29102 7.50008V12.5001C2.29102 16.3417 3.65768 17.7084 7.49935 17.7084H12.4993C16.341 17.7084 17.7077 16.3417 17.7077 12.5001V7.50008C17.7077 3.65841 16.341 2.29175 12.4993 2.29175H7.49935Z"
                fill="#292D32"
            />
        </svg>
    )
}
