import React from 'react'

export const IconCheck = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path d="M9 16.4375C5.1675 16.4375 2.0625 13.3325 2.0625 9.5C2.0625 5.6675 5.1675 2.5625 9 2.5625C12.8325 2.5625 15.9375 5.6675 15.9375 9.5C15.9375 13.3325 12.8325 16.4375 9 16.4375Z" stroke="#11DC2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.65496 10.685L7.33496 9.36501" stroke="#11DC2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.2953 8.045L8.65527 10.685" stroke="#11DC2E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
