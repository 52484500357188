import multicall from "utils/multicall";
import stockAbi from "config/abi/stocks.json"
import { DEFAULT_ID } from "config";
import { StockType } from "config/constants/stocks/type";
import { Stock, StockDetail, StockItem } from "./type";
import BigNumber from "bignumber.js";

export const getDataStock = async (config:StockType, chainId:number, account:string): Promise<{stock:Stock}> => {
    try {
        const calls = [
            {
                address: config?.contract?.address,
                name: 'ADMIN_ROLE',
                params: []
            },
            {
                address: config?.contract?.address,
                name: 'approver',
                params: []
            },
            {
                address: config?.contract?.address,
                name: 'issuePrice',
                params: []
            },
            {
                address: config?.contract?.address,
                name: 'totalSupply',
                params: [DEFAULT_ID]
            },
            {
                address: config?.contract?.address,
                name: 'uri',
                params: [DEFAULT_ID]
            },
            {
                address: config?.contract?.address,
                name: 'getShareholders',
                params: []
            },
            {
                address: config?.contract?.address,
                name: 'isManual',
                params: []
            }
        ] 

        const getIsAdmin = async() => {
            if(account?.length > 0) {
                const calls = [
                    {
                        address: config?.contract?.address,
                        name: 'hasRole',
                        params: [result[0][0], account]
                    }
                ]
                const [resultAdmin] = await multicall(stockAbi, calls, chainId);
                return resultAdmin[0]
            }
        }
        const result = await multicall(stockAbi, calls, chainId);
        const resultAdmin = await getIsAdmin()
        
        const mergeShareHolders = result[5][0]?.map((dt) => {
            return {
                shareholderAddress: dt?.shareholder,
                amount: new BigNumber(dt?.numberOfShares?.toString()).toString()
            }
        })
        const mergeData = {
            ...config,
            approvedAddress: result[1][0] ,
            adminRole: result[0][0] ,
            logo: result[4][0],
            totalRelease: new BigNumber(result[3][0]?.toString()).toString(),
            issuePrice: new BigNumber(result[2][0]?.toString()).toString(), 
            isAdmin: resultAdmin,
            shareHolders: mergeShareHolders.filter(item => new BigNumber(item.amount).isGreaterThan(0)),
            isManual: result[6][0]
        }
        
        return { stock: mergeData }
        
    } catch (error) {
        console.log(error)
        return { stock: null }
    }
} 