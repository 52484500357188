import { Text, Flex, Button } from "@pancakeswap/uikit";
import styled from "styled-components";
import { MENU_HEIGHT } from "@pancakeswap/uikit/src/widgets/Menu/config";
import ConnectWalletButton from "components/ConnectWalletButton";

export const PageFullWidth = styled(Flex)`
    width: 100%;
    height: calc(100vh - ${MENU_HEIGHT}px);
    flex-direction: column;
    align-items:center;
    justify-content: center;
    /* background: url("/images/logo/bg_login.png?version=1.0");
    background-size: contain;
    background-repeat:no-repeat;
    background-position: center; */
    @media screen and (max-width: 600px){
        height: 100%;
    }
`
export const ContaierLogin = styled(Flex)`
    width: 700px;
    height: 550px;
    border-radius: 12px;
    /* background: url("/images/logo/bg_container_login.png?version=1.0") ${({theme}) => theme.colors.white};
    background-size: 100%;
    background-repeat:no-repeat;
    background-position: left; */
    @media screen and (min-width: 601px) and (max-width: 1280px) {
        width: 600px;
        height: 450px;
    }
    @media screen and (max-width: 600px){
        width: 100%;
        height: 60vh;
        border-radius: 0px;
    }
`
export const ContainerImage = styled(Flex)`
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > img {
        width: auto;
        height: 350px;
        @media screen and (min-width: 601px) and (max-width: 1440px){
            width: 300px;
            height: auto;
        }
        @media screen and (max-width: 600px){
            width: auto;
            height: 207px;
        }
    }
`
export const CsButton = styled(ConnectWalletButton)`
    width: 300px;
    margin-top:2rem;
`
export const CsButtonLogout = styled(Button)`
    width: 300px;
    margin-top:2rem;
`

export const CsFlex = styled(Flex)`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 600px){
        width: 100vw;
        height: 90vh;
        justify-content: flex-end;
    }
`
