export const IconNoData = (props) => {
    return (
        <svg {...props} width="91" height="80" viewBox="0 0 91 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_232_18160"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="7"
                y="0"
                width="82"
                height="80"
            >
                <path
                    d="M48.215 80C70.4523 80 88.4862 62.1017 88.4862 40C88.4862 17.8983 70.4523 0 48.215 0C25.9777 0 7.94385 17.8983 7.94385 40C7.94385 62.1017 25.9777 80 48.215 80Z"
                    fill="#EAEEF9"
                />
            </mask>
            <g mask="url(#mask0_232_18160)">
                <path
                    d="M48.2712 80C70.5085 80 88.5424 62.1017 88.5424 40C88.5424 17.8983 70.5085 0 48.2712 0C26.0339 0 8 17.8983 8 40C8 62.1017 26.0339 80 48.2712 80Z"
                    fill="#EAEEF9"
                />
                <path
                    d="M79.063 34.7793V59.2538C79.063 64.3386 74.9274 68.4742 69.7748 68.4742H26.7918C21.7071 68.4742 17.5715 64.4064 17.5037 59.3216C17.5037 59.2538 17.5037 59.2538 17.5037 59.186V34.7793C17.5037 34.7115 17.5037 34.7115 17.5037 34.6437C17.5037 34.5081 17.5037 34.3725 17.5715 34.2369C17.6393 34.0335 17.7071 33.8979 17.7748 33.6945L29.3003 11.6606C29.707 10.7793 30.5884 10.3047 31.5376 10.3047H64.9613C65.9104 10.3047 66.724 10.7793 67.1986 11.6606L78.724 33.6945C78.7918 33.8301 78.8596 34.0335 78.9274 34.2369C79.063 34.3725 79.063 34.5759 79.063 34.7793Z"
                    fill="url(#paint0_linear_232_18160)"
                />
                <g filter="url(#filter0_d_232_18160)">
                    <path
                        d="M57.0961 34.9831H39.6723C38.9266 34.9831 38.3164 34.4407 38.3164 33.6271V9.83054C38.3164 9.08478 38.8588 8.47461 39.6723 8.47461H57.0283C57.774 8.47461 58.3842 9.01698 58.3842 9.83054V33.6271C58.3842 34.3729 57.8418 34.9831 57.0961 34.9831Z"
                        fill="#F5F8FF"
                    />
                </g>
                <g filter="url(#filter1_d_232_18160)">
                    <path
                        d="M62.8599 40.61H33.9107C32.9616 40.61 32.2158 39.7965 32.2158 38.9151V15.7965C32.2158 14.8473 33.0294 14.1016 33.9107 14.1016H62.8599C63.809 14.1016 64.5548 14.9151 64.5548 15.7965V38.9151C64.487 39.8643 63.809 40.61 62.8599 40.61Z"
                        fill="#F5F8FF"
                    />
                </g>
                <g filter="url(#filter2_d_232_18160)">
                    <path
                        d="M66.181 47.1868H30.6556C29.6387 47.1868 28.7573 46.3054 28.7573 45.3563V22.5766C28.7573 21.5597 29.6387 20.7461 30.6556 20.7461H66.181C67.198 20.7461 68.0793 21.6274 68.0793 22.5766V45.2885C68.0116 46.3732 67.198 47.1868 66.181 47.1868Z"
                        fill="#F5F8FF"
                    />
                </g>
                <g filter="url(#filter3_d_232_18160)">
                    <path
                        d="M69.7743 53.5594H27.0625C25.9099 53.5594 25.0286 52.6102 25.0286 51.5933V29.1526C25.0286 28.0001 25.9777 27.1865 27.0625 27.1865H69.7743C70.9269 27.1865 71.8082 28.1357 71.8082 29.1526V51.5255C71.7404 52.678 70.9269 53.5594 69.7743 53.5594Z"
                        fill="#F5F8FF"
                    />
                </g>
                <path
                    d="M56.9609 36.0675H40.8931C40.4864 36.0675 40.0796 35.7286 40.0796 35.254C40.0796 34.8472 40.4186 34.4404 40.8931 34.4404H56.9609C57.3677 34.4404 57.7745 34.7794 57.7745 35.254C57.7745 35.7286 57.4355 36.0675 56.9609 36.0675Z"
                    fill="#E6ECF5"
                />
                <path
                    d="M52.283 40.8134H31.9441C31.6729 40.8134 31.4695 40.61 31.4695 40.3388C31.4695 40.0676 31.6729 39.8643 31.9441 39.8643H52.2152C52.4864 39.8643 52.6898 40.0676 52.6898 40.3388C52.8254 40.4744 52.622 40.8134 52.283 40.8134Z"
                    fill="#E6ECF5"
                />
                <path
                    d="M65.6381 40.8134H55.4008C55.1297 40.8134 54.9263 40.61 54.9263 40.3388C54.9263 40.0676 55.1297 39.8643 55.4008 39.8643H65.5703C65.8415 39.8643 66.0449 40.0676 66.0449 40.3388C66.1805 40.4744 65.9771 40.8134 65.6381 40.8134Z"
                    fill="#E6ECF5"
                />
                <g filter="url(#filter4_d_232_18160)">
                    <path
                        d="M75.5259 36.0064V59.2105C75.5259 62.5107 72.8856 65.151 69.5254 65.151H27.0418C23.7415 65.151 21.0413 62.5107 21.0413 59.2105V35.8864C21.0413 35.7664 21.0413 35.6464 21.1013 35.5264H32.7485C34.7887 35.5264 36.4688 37.1465 36.4688 39.2467C36.4688 40.2668 36.8889 41.2268 37.5489 41.8869C38.269 42.607 39.1091 42.967 40.1892 42.967H57.1159C59.1561 42.967 60.8363 41.3469 60.8363 39.2467C60.8363 38.2266 61.2563 37.2665 61.9163 36.6065C62.6364 35.8864 63.4765 35.5264 64.4966 35.5264H75.4058C75.5259 35.6464 75.5259 35.8264 75.5259 36.0064Z"
                        fill="white"
                    />
                </g>
                <g filter="url(#filter5_d_232_18160)">
                    <path
                        d="M79.063 34.7797V61.7627C79.063 65.4915 76.0799 68.4746 72.2833 68.4746H24.2833C20.5545 68.4746 17.5037 65.4915 17.5037 61.7627V34.6441C17.5037 34.5085 17.5037 34.3729 17.5715 34.2373H33.0291C35.3342 34.2373 37.2325 36.0678 37.2325 38.4407C37.2325 39.5932 37.707 40.678 38.4528 41.4237C39.2664 42.2373 40.2155 42.6441 41.4359 42.6441H55.1986C57.5037 42.6441 59.402 40.8136 59.402 38.4407C59.402 37.2882 59.8765 36.2034 60.6223 35.4576C61.4359 34.6441 62.385 34.2373 63.5376 34.2373H78.9274C79.063 34.3729 79.063 34.5763 79.063 34.7797Z"
                        fill="url(#paint1_linear_232_18160)"
                    />
                </g>
                <path
                    d="M72.2828 58.7119H58.3167C57.8421 58.7119 57.4353 58.3729 57.4353 57.8306C57.4353 57.356 57.7743 56.9492 58.3167 56.9492H72.1472C72.6217 56.9492 73.0285 57.2882 73.0285 57.8306C73.0963 58.2374 72.6895 58.7119 72.2828 58.7119Z"
                    fill="#E6ECF5"
                />
                <path
                    d="M66.0455 62.9834H58.3167C57.8421 62.9834 57.4353 62.6444 57.4353 62.1021C57.4353 61.6275 57.7743 61.2207 58.3167 61.2207H65.9777C66.4523 61.2207 66.859 61.5597 66.859 62.1021C66.9268 62.5088 66.52 62.9834 66.0455 62.9834Z"
                    fill="#E6ECF5"
                />
                <path
                    d="M61.2366 65.8307C60.9654 66.9832 60.5586 68.2035 60.0841 69.2205C58.7959 71.729 56.762 73.6951 54.2536 74.9832C51.6773 76.2713 48.6264 76.8137 45.5756 76.1357C38.3214 74.5764 33.7112 67.5934 35.2027 60.4069C36.6942 53.2205 43.6773 48.5425 50.8637 50.1019C53.44 50.6442 55.6773 51.9324 57.5756 53.6951C60.8298 56.8815 62.118 61.4239 61.2366 65.8307Z"
                    fill="url(#paint2_linear_232_18160)"
                />
                <path
                    d="M52.418 61.8987H49.3671V58.8479C49.3671 58.2377 48.8925 57.6953 48.2146 57.6953C47.6044 57.6953 47.062 58.1699 47.062 58.8479V61.8987H44.0112C43.401 61.8987 42.8586 62.3733 42.8586 63.0512C42.8586 63.7292 43.3332 64.2038 44.0112 64.2038H47.062V67.2546C47.062 67.8648 47.5366 68.4072 48.2146 68.4072C48.8247 68.4072 49.3671 67.9326 49.3671 67.2546V64.2038H52.418C53.0281 64.2038 53.5705 63.7292 53.5705 63.0512C53.5705 62.3733 53.0281 61.8987 52.418 61.8987Z"
                    fill="white"
                />
            </g>
            <path
                d="M87.4579 20.8816C88.993 20.8816 90.2375 19.6371 90.2375 18.1019C90.2375 16.5668 88.993 15.3223 87.4579 15.3223C85.9227 15.3223 84.6782 16.5668 84.6782 18.1019C84.6782 19.6371 85.9227 20.8816 87.4579 20.8816Z"
                fill="#EAEEF9"
            />
            <path
                d="M84.6778 11.864C85.7263 11.864 86.5761 11.0141 86.5761 9.96569C86.5761 8.91728 85.7263 8.06738 84.6778 8.06738C83.6294 8.06738 82.7795 8.91728 82.7795 9.96569C82.7795 11.0141 83.6294 11.864 84.6778 11.864Z"
                fill="#EAEEF9"
            />
            <path
                d="M7.7965 18.1013C8.8449 18.1013 9.6948 17.2514 9.6948 16.203C9.6948 15.1546 8.8449 14.3047 7.7965 14.3047C6.74809 14.3047 5.89819 15.1546 5.89819 16.203C5.89819 17.2514 6.74809 18.1013 7.7965 18.1013Z"
                fill="#EAEEF9"
            />
            <path
                d="M3.52542 59.8643C5.47246 59.8643 7.05085 58.2859 7.05085 56.3389C7.05085 54.3919 5.47246 52.8135 3.52542 52.8135C1.57839 52.8135 0 54.3919 0 56.3389C0 58.2859 1.57839 59.8643 3.52542 59.8643Z"
                fill="#EAEEF9"
            />
            <defs>
                <filter
                    id="filter0_d_232_18160"
                    x="16.3164"
                    y="-12.5254"
                    width="64.0679"
                    height="70.5088"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_232_18160" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_232_18160" result="shape" />
                </filter>
                <filter
                    id="filter1_d_232_18160"
                    x="10.2158"
                    y="-6.89844"
                    width="76.3389"
                    height="70.5088"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_232_18160" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_232_18160" result="shape" />
                </filter>
                <filter
                    id="filter2_d_232_18160"
                    x="6.75732"
                    y="-0.253906"
                    width="83.322"
                    height="70.4404"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_232_18160" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_232_18160" result="shape" />
                </filter>
                <filter
                    id="filter3_d_232_18160"
                    x="3.02856"
                    y="6.18652"
                    width="90.7795"
                    height="70.373"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_232_18160" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_232_18160" result="shape" />
                </filter>
                <filter
                    id="filter4_d_232_18160"
                    x="8.04126"
                    y="19.5264"
                    width="80.4846"
                    height="55.625"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-3" />
                    <feGaussianBlur stdDeviation="6.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_232_18160" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_232_18160" result="shape" />
                </filter>
                <filter
                    id="filter5_d_232_18160"
                    x="-4.49634"
                    y="23.2373"
                    width="105.559"
                    height="78.2373"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_232_18160" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_232_18160" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_232_18160"
                    x1="48.2882"
                    y1="12.3254"
                    x2="48.2882"
                    y2="44.9754"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B0BACC" />
                    <stop offset="1" stopColor="#969EAE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_232_18160"
                    x1="48.2633"
                    y1="33.4454"
                    x2="48.2633"
                    y2="68.8437"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FDFEFF" />
                    <stop offset="0.9964" stopColor="#ECF0F5" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_232_18160"
                    x1="48.2168"
                    y1="50.7268"
                    x2="48.2168"
                    y2="65.6726"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B0BACC" />
                    <stop offset="1" stopColor="#969EAE" />
                </linearGradient>
            </defs>
        </svg>
    )
}
