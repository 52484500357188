import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../index';
import { useEffect } from 'react';
import { fetchDataStock, fetchLoading } from './actions';
import { getStocksConfig } from 'config/constants/stocks';
import { getDataStock} from './fetchDataStock';


export const GetDataShareHolders = () => {
    const dataShareHolders = useSelector<AppState, AppState['shareholders']>((state) => state?.shareholders)
    return dataShareHolders
}


export const UseCoreStock = (contractAddress: string, chainId: number, account:string) => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        (async () => {
            dispatch(fetchLoading({isLoading: !false}))
            const config = await getStocksConfig(chainId)
            const findItem = config?.find((dt) => dt?.contract?.address?.toLowerCase() === contractAddress?.toLowerCase())
            
            if(findItem) {
                const stockData = await getDataStock(findItem, chainId, account)
                dispatch(fetchDataStock(stockData))
            } else {
                dispatch(fetchDataStock({stock: null}))
            }
            dispatch(fetchLoading({isLoading: false}))
        })();
    },[contractAddress, chainId, account])
};