export const IconUpload = (props) => {
    return (
        <svg width="130" height="130" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="180" height="180" rx="93" fill="#355DFF" fill-opacity="0.1" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M120.922 59.6055V84.2704H96.2272C93.3233 84.2704 90.5154 85.422 88.4755 87.4854C86.4356 89.5488 85.2597 92.332 85.2597 95.2352V130.889H60.5889C59.125 130.889 57.7331 130.313 56.7251 129.281C55.6932 128.274 55.1172 126.858 55.1172 125.418V70.0905C55.1172 68.6269 55.6932 67.2353 56.7251 66.2036C59.365 63.5643 64.5727 58.3579 67.2126 55.7186C68.2445 54.6869 69.6605 54.1111 71.1004 54.1111H115.426C118.45 54.1111 120.922 56.5584 120.922 59.6055Z" fill={props?.fill} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M96.2272 89.7648C94.7632 89.7648 93.3713 90.3406 92.3634 91.3723C91.3314 92.38 90.7554 93.7956 90.7554 95.2352V130.889H126.418C127.882 130.889 129.274 130.313 130.281 129.281C131.313 128.274 131.889 126.858 131.889 125.418V89.7648H96.2272Z" fill="#232632" />
            <path d="M110.725 119.644V100.9" stroke="#E4E8F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M110.725 100.846L101.375 109.692" stroke="#E4E8F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M110.724 100.846L120.074 109.692" stroke="#E4E8F9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>



    )
}
