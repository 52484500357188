import styled, { DefaultTheme } from "styled-components";
import { InputProps, scales } from "./types";

interface StyledInputProps extends InputProps {
    theme: DefaultTheme;
}

/**
 * Priority: Warning --> Success
 */
const getBoxShadow = ({ isSuccess = false, isWarning = false, theme }: StyledInputProps) => {
    if (isWarning) {
        return theme.shadows.warning;
    }

    if (isSuccess) {
        return theme.shadows.success;
    }

    return theme.shadows.inset;
};

const getHeight = ({ scale = scales.MD }: StyledInputProps) => {
    switch (scale) {
        case scales.SM:
            return "32px";
        case scales.LG:
            return "46px";
        case scales.MD:
        default:
            return "46px";
    }
};

const Input = styled.input<InputProps>`
    background-color: transparent;
    border-radius: 12px;
    box-shadow: ${getBoxShadow};
    color: ${({ theme }) => theme.colors.text};
    display: block;
    font-size: 14px;
    height: ${getHeight};
    outline: 0;
    padding: 0 16px;
    width: 100%;
    border: 2px solid ${({ theme }) => theme.colors.cardBorder};

    &::placeholder {
        color: ${({ theme }) => theme.colors.text90};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.backgroundDisabled};
        box-shadow: none;
        color: ${({ theme }) => theme.colors.textDisabled};
        cursor: not-allowed;
    }
`;

Input.defaultProps = {
    scale: scales.MD,
    isSuccess: false,
    isWarning: false,
};

export default Input;
